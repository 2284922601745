$primary-color: #43a047;
$accent-color: #f5f5f5;
$warn-color: #e53935;

$primary-color-active: #409a44;

$text-color-white: white !default;
$text-color-black: black !default;

$background-color: #efefef !default;
$background-color-white: white !default;
$background-color-grey: $accent-color !default;

$background-color-black: rgba(30, 30, 30, 0.97) !default;
$background-color-black-2: rgba(0, 0, 0, 0.04) !default;
$background-color-black-3: rgba(0, 0, 0, 0.87) !default;



$light-black: #444 !default;
$dark-gray: #9A9A9A !default;
$light-gray: #E3E3E3 !default;

$border-radius-small: 0.1875rem !default;

$font-size-base: 0.9375rem !default; // 15px
$font-size-normal: 1rem !default; // 16px
$font-size-smaller: 0.75rem !default; // 12px
$font-size-small: 0.87rem !default; // 14px
$font-size-normal-2: 1.125rem !default; // 18px
$font-size-large: 1.75rem !default; // 28px
$font-size-larger: 2rem !default; // 32px
$font-size-larger-x: 2.5rem !default; //40px

// $font-size-navbar: 1em !default;
// $font-size-mini: 0.7142em !default;
// $font-size-large: 1em !default;
// $font-size-large-navbar: 20px !default;
// $font-size-blockquote: 1.1em !default; // ~ 15px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default; //normal
$font-weight-semi: 500 !default;
$font-weight-semi-2: 600 !default;
$font-weight-bold: 700 !default; // bold

$box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
$box-shadow-unread-msg: 2px 2px 3px -3px rgba(67, 160, 71, 1), 0px 8px 10px 1px rgba(67, 160, 71, 1), 0px 3px 14px 2px rgba(67, 160, 71, 1);

// a line as a delimited in form
$line-form-color: #ced4da !default;
//all textarea border
$textarea-border-color: #ced4da !default;

// status commisions colors
$status-confirmed-color: #A6D220;
$status-pending-color: #F7B400;
$status-declined-color: #FF5558;
$status-failed-color: #FF000A;
$status-request-color: #F79731;
$status-paid-color: #86C125;
$status-payment-status-color: #535352;

$green-text-color: #94D710 !default;

$text-color-link-alt: #007bff !default;

$footer-link-color: #777777 !default;

$coupon-code-backgroud: #DBF5FC !default;
$coupon-code-border: #BBE5F0 !default;

$unread-msg-background:rgba(96,195,100, 1) !default; // #60c364;
$unread-msg-background-hover: rgba(96,195,100, 0.8)  !default;
