@charset "UTF-8";

/*CSS Star Rating Elements
=================================================================*/

/*HELPER*/

.center-all {
    display: flex;
    align-items: center;
    justify-content: center;
}


/*
Star element
==================================================================*/

.star {
    position: relative;
    width: $default-star-width;
    height: $default-star-height;
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    i {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        font-style: normal;

        &.star-half,
        &.star-filled {
            opacity: 0;
        }
    }

    i {
        top: 1px;
        display: none;
    }

    &.icon i {
        font-size: $default-star-font-size;
        line-height: $default-star-line-height;

        &.star-empty:before {
            content: $default-star-character-empty;
        }

        &.star-half:before {
            content: $default-star-character-half;
        }

        &.star-filled:before {
            content: $default-star-character-filled;
        }
    }

    &.custom-icon i {
        font-size: 18px;
        line-height: 18px;
    }


    /*
  Fill states
  ==================================================================*/
    &.empty {

        svg,
        i {

            &.star-half,
            &.star-filled {
                opacity: 0;
            }

            &.star-empty {
                opacity: 1;
            }
        }
    }

    &.half {

        svg,
        i {

            &.star-filled,
            &.star-empty {
                opacity: 0;
            }

            &.star-half {
                opacity: 1;
            }
        }
    }

    &.filled {

        svg,
        i {

            &.star-empty,
            &.star-filled {
                opacity: 0;
            }

            &.star-filled {
                opacity: 1;
            }
        }
    }

    /*
  Colors
  ==================================================================*/
    &.default {
        svg {
            fill: $color-default-rating;
        }

        i {
            color: $color-default-rating;
        }
    }

    &.negative {
        svg {
            fill: $color-negative-rating;
        }

        i {
            color: $color-negative-rating;
        }
    }

    &.ok {
        svg {
            fill: $color-ok-rating;
        }

        i {
            color: $color-ok-rating;
        }
    }

    &.positive {
        svg {
            fill: $color-positive-rating;
        }

        i {
            color: $color-positive-rating;
        }
    }

    /*
  Icon Types
  ==================================================================*/
    &.svg {
        i {
            display: none;
        }

        svg {
            display: flex;
        }
    }

    &.custom-icon,
    &.icon {
        svg {
            display: none;
        }

        i {
            display: flex;
        }
    }

    /*
  Star Size
  ===================================================================*/
    &.small {
        width: $small-star-width;
        height: $small-star-height;

        i {
            font-size: $small-star-font-size;
            line-height: $small-star-line-height;
        }
    }

    &.medium {
        width: $default-star-width;
        height: $default-star-width;

        i {
            font-size: $default-star-font-size;
            line-height: $default-star-line-height;
        }
    }

    &.large {
        width: $large-star-width;
        height: $large-star-height;

        i {
            font-size: $large-star-font-size;
            line-height: $large-star-line-height;
        }
    }

    /*
Disabled
=================================================*/
    &.disabled {
        opacity: $disabled-opacity;
    }

    /*
  Direction
  =================================================*/
    &.direction-rtl {

        svg,
        i {
            &.star-half {
                transform: scale(-1, 1);
            }
        }
    }

    &.direction-ltr {

        svg,
        i {
            &.star-half {
                transform: scale(1, 1);
            }
        }
    }

}

/*
Label Element
===================================================================*/

.label-value {
    font-size: $default-label-font-size;
    line-height: $default-label-line-height;

    /*
  Sizes
  =================================================================*/
    &.small {
        font-size: $small-label-font-size;
        line-height: $small-label-line-height;
    }

    &.medium {
        font-size: $default-label-font-size;
        line-height: $default-star-line-height;
    }

    &.large {
        font-size: $large-label-font-size;
        line-height: $large-star-line-height;
    }

    /*
  Disabled
  =================================================*/
    &.disabled {
        opacity: $disabled-opacity;
    }

}

/*
Star Container
==================================================================*/
.star-container {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;

    margin-left: $default-stars-label-margin;
    margin-right: $default-stars-label-margin;

    transition: all $default-animation-speed $default-animation-type;

    +.star {
        margin-left: $default-stars-label-margin;
    }

    .star {
        @extend .default;
        transition: all $default-animation-speed $default-animation-type;

        svg,
        i {
            transition: all $default-animation-speed $default-animation-type;
        }
    }

    svg {
        z-index: 2;
    }

    i {
        z-index: 1;
    }

    /*
 Direction
 ===================================================*/
    &.direction-rtl {
        direction: rtl;

        .star {

            svg,
            i {
                &.star-half {
                    transform: scale(-1, 1);
                }
            }
        }
    }

    &.direction-ltr {
        direction: ltr;

        .star {

            svg,
            i {
                &.star-half {
                    transform: scale(1, 1);
                }
            }
        }
    }

    /*
  Space
  ======================================================*/
    &.space-no {
        flex: 1 1 auto;
        justify-content: center;
    }

    &.space-between {
        flex: 1 1 auto;
        justify-content: space-between;
    }

    &.space-around {
        flex: 1 1 auto;
        justify-content: space-around;
    }

}