.social-incons-footer {
    .waves-effect {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    a.waves-effect,
    a.waves-light {
        display: inline-block;
    }

    .btn-floating {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        width: 47px;
        height: 47px;
        position: relative;
        z-index: 1;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        margin: 10px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;
        -webkit-appearance: unset;
        -moz-appearance: unset;
        appearance: unset;
    }

    .btn-floating i {
        font-size: 1.25rem;
        line-height: 47px;
    }

    .btn-floating i {
        display: inline-block;
        width: inherit;
        text-align: center;
        color: $text-color-white;
    }

    .btn-floating:hover {
        -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .btn-floating:before {
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    .btn-fb {
        background-color: #3b5998 !important;
        color: $text-color-white !important;
    }

    .btn-fb:hover {
        background-color: #4264aa;
    }

    .btn-fb:focus {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .btn-fb:active,
    .btn-fb:focus {
        background-color: #1e2e4f;
    }

    .btn-fb:not([disabled]):not(.disabled):active {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        background-color: #1e2e4f !important;
    }

    .btn-fb:not([disabled]):not(.disabled):active:focus {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .btn-ins {
        background-color: transparent !important;
        color:  $text-color-white !important;
    }

    .btn-ins:hover {
        background-color: #356b99;
    }

    .btn-ins:focus {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .btn-ins:active,
    .btn-ins:focus {
        background-color: #14293a;
    }

    .btn-ins:not([disabled]):not(.disabled):active {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        background-color: #14293a !important;
    }

    .btn-ins:not([disabled]):not(.disabled):active:focus {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .btn-pin {
        background-color: #c61118 !important;
        color:  $text-color-white !important;
    }

    .btn-pin:hover {
        background-color: #dd131b;
    }

    .btn-pin:focus {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .btn-pin:active,
    .btn-pin:focus {
        background-color: #68090d;
    }

    .btn-pin:not([disabled]):not(.disabled):active {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        background-color: #68090d !important;
    }

    .btn-pin:not([disabled]):not(.disabled):active:focus {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .fab {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
    }

    .fa-pinterest:before {
        content: "\f0d2";
    }

    .fa-instagram:before {
        content: "\f16d";
    }

    .fa-facebook-f:before {
        content: "\f39e";
    }

    .fab {
        font-family: "Font Awesome 5 Brands";
    }

    .faimg {
        background-size: 47px 47px;
        height: 47px;
        width: 47px;
    }

    .faimg-instagram {
        background-image: url('./../assets/images/icons/instagram.png');
    }
}

.social-platform-login {

    .btn-floating {
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        width: 47px;
        height: 47px;
        position: relative;
        z-index: 1;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        margin: 10px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;
        -webkit-appearance: unset;
        -moz-appearance: unset;
        appearance: unset;
    }

    .btn-floating i {
        font-size: 1.25rem;
        line-height: 47px;
    }

    .btn-floating i {
        display: inline-block;
        width: inherit;
        text-align: center;
        color: $text-color-white;
    }

    .fab {
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        font-size: 28px;
        margin-right: 10px;
        line-height: 29px;
    }

    .fab {
        font-family: "Font Awesome 5 Brands";
    }

    .fa-facebook-f:before {
        content: "\f39e";
    }

    .fa-facebook-square:before {
        content: "\f082";
    }

    .fa-google:before {
        content: "\f1a0";
    }

    .btn-social-icon {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-weight: $font-weight-normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        font-size: $font-weight-normal;
        border-radius: .25rem;
        transition: all .2s ease-in-out;
        text-transform: none;
        padding: 6px 10px 5px 10px;
        line-height: 19px;
        margin-bottom: 9px;

        .text {
            line-height: 29px;
        }
    }

    .btn-fb {
        background-color: #3b5998 !important;
        color: $text-color-white !important;
    }

    .btn-google {
        background-color: #dd4b39 !important;
        color: $text-color-white !important;
        display: inline-flex;
    }

    .btn-google-wrapper {
        display: inline-flex;
        padding: 0;
    }

    .btn-fb:hover {
        background-color: #4264aa;
    }

    .btn-google:hover {
        background-color: #c23321;
    }
}

.social-incons-link {

    .fab {
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        font-size: 28px;
        margin-right: 10px;
        line-height: 29px;
    }

    .fab {
        font-family: "Font Awesome 5 Brands";
    }

    .btn-fb {
        background-color: #3b5998 !important;
        color: $text-color-white !important;
    }

    .btn-google {
        background-color: #dd4b39 !important;
        color: $text-color-white !important;
    }

    .fa-facebook-f:before {
        content: "\f39e";
    }

    .fa-facebook-square:before {
        content: "\f082";
    }

    .fa-google:before {
        content: "\f1a0";
    }

    .btn-floating {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        width: 47px;
        height: 47px;
        position: relative;
        z-index: 1;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        margin: 10px;
        border-radius: 50%;
        padding: 0;
        appearance: unset;
    }

    .btn-floating i {
        font-size: 1.25rem;
        line-height: 47px;
    }

    .btn-floating i {
        display: inline-block;
        width: inherit;
        text-align: center;
        color: $text-color-white;
    }
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "Font Awesome 5 Brands";
    font-style: normal;
    font-weight: $font-weight-normal;
    src: url("./../assets/fonts/fa-brands-400.eot");
    src: url("./../assets/fonts/fa-brands-400.eot") format("embedded-opentype"),
        url("./../assets/fonts/fa-brands-400.woff2") format("woff2"),
        url("./../assets/fonts/fa-brands-400.woff") format("woff"),
        url("./../assets/fonts/fa-brands-400.ttf") format("truetype"),
        url("./../assets/fonts/fa-brands-400.svg") format("svg");
}