@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$primary: mat.define-palette(mat.$green-palette, 600, 100, 800);
$accent: mat.define-palette(mat.$grey-palette, 100, 100, 100);
$warn: mat.define-palette(mat.$red-palette, 600);

$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);

@mixin supporting-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $color-config: mat.get-color-config($theme);

    $primary: map.get($color-config, 'primary');
    $accent: map.get($color-config, 'accent');

    $primary-color: mat.get-color-from-palette($primary);
    $accent-color: mat.get-color-from-palette($accent);

    mat-toolbar {
        color: $accent-color;
    }

    mat-toolbar .mat-list-item.mat-list-item-focus .mat-icon {
        color: $accent-color;
    }

    footer {
        background-color: $primary-color;
        color: $accent-color;
    }

    footer a {
        color: $accent-color;
    }

    .mat-card-w {
        .mat-card-subtitle {
            color: $primary-color;
        }
    }
}

@include supporting-theme($theme);