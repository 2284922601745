@import "./variables.scss";
@import "./theme.scss";
@import "./social.scss";
@import "./custom-dialog.scss";
@import "./cards.scss";

/* You can add global styles to this file, and also import other style files */

html,
body {
    box-sizing: border-box;
    font-weight: $font-weight-normal;
    height: 100%;
    width: 100%;
    user-select: text !important;
}

body {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    background-color: $background-color;
}

app-root {
    user-select: text !important;
    display: block;
    flex: 0 1 auto;
    min-height: 100%;
    width: 100%;
    display: flex;

    a:hover {
        text-decoration: none;
        // color: inherit;
    }
}

mat-toolbar {
    font-weight: $font-weight-normal !important;

    &.main-toolbar {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 100;
        color: $text-color-white;
    }
}

.mat-body,
.mat-dialog-content,
.mat-card,
.mat-card-content {
    font-size: $font-size-base;
    color: $text-color-black;
}

.main {
    &.mat-drawer-container {
        overflow: unset;
        flex: 1 1 0%;
        -ms-flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;

        &.top-nav {
            margin-top: 56px;
        }

        @media only screen and (min-width: 600px) {
            &.top-nav {
                margin-top: 64px;
            }
        }
    }

    .mat-drawer-content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
        -ms-flex: 1 1 auto;
        overflow: unset;
        height: unset;
        width: auto;
    }

    .mat-accordion {
        .mat-expansion-panel {
            &:first-of-type {
                margin-top: 3px;
            }

            &:last-of-type {
                margin-bottom: 4em;
            }
        }
    }

    .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']) {
        background: white;
    }

    .main-content {
        position: relative;
        margin: 0;
        padding: 0;
        width: 100%;
        min-height: 200px;
        flex: 1 1 0%;
        -ms-flex: 1 1 auto;

        .content-body, .container-fluid, .container {
            padding: 25px 15px 20px 15px;
        }

        @media only screen and (max-width: 768px) {
            .content-body, .container, .container-fluid {
                padding: 15px 10px;
            }
        }

        @media only screen and (max-width: 576px) {
            .content-body, .container, .container-fluid {
                padding: 10px 7px;
            }
        }
    }
}

[hidden],
.hidden {
    display: none !important;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

@media screen and (max-width: 411px) {
    .row {
        width: 100%;
    }
}

.bold, b {
    font-weight: 600 !important;
}

.col-xs-12 {
    padding-left: 0;
    padding-right: 0;
}

img {
    max-width: 100%;
}

select:focus {
    outline: none;
}

.form-content {
    margin: auto;
}

.mat-icon {
    user-select: none;
}

textarea {
    border: 1px solid $textarea-border-color !important;
    padding: 6px !important;
}

button:focus {
    outline: unset;
}

.nav-spacer {
    flex: 1 1 auto;
}

.list {
    list-style: none;
}

.mat-button-base {
    &.btn-lg {
        padding: 1.2rem 2rem;
        font-size: 1.1em;
        line-height: 25px;
    }

    &.btn-sm {
        padding: 0.5rem 1.6rem;
        line-height: 25px;
    }

    &.btn-md {
        padding: 0.6rem 1.6rem;
        font-size: $font-size-base;
        line-height: 25px;
    }
}

mat-sidenav-container,
.mat-drawer-container {
    background-color: $background-color;
}

mat-sidenav {

    &.mat-drawer {
        position: fixed;
        height: 100vh;
        min-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 70px;
        z-index: 100;
        min-width: 260px;
        width: 300px;
        max-width: 100vw;
    }

    mat-nav-list {
        overflow-y: auto;

        a mat-icon {
            font-size: $font-size-normal-2;
            margin-right: 10px;
        }

        a .menu-reload-btn mat-icon {
            margin-right: 0;
        }
    }

    .mat-selection-list {
        overflow-y: auto;
    }

    h3 {
        margin-bottom: 0px;
        margin-left: 20px;
    }
}

.mat-list-base .mat-list-item {
    font-size: $font-size-normal-2;

    .mat-line.side-line {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .mat-list-item-content {
        padding: 0 10px;
    }
}

.app-topnav .mat-list-base .mat-list-item {
    .mat-list-item-content {
        padding: 0 10px;
    }
}

@media screen and (max-width: 1280px) {
    .app-topnav .mat-list-base a.mat-list-item {
        font-size: $font-size-normal;
    }
}

.fab-theme {
    position: absolute;
    right: 20px;
    margin-top: 30px;
}

// .mat-sidenav-transition .mat-sidenav {
//     /* custom animation to grow and shrink width */
//     -webkit-transition: width .3s !important; /* For Safari 3.1 to 6.0 */
//     transition: width .3s !important;
// }

.error-div {
    // margin-top: 50px;
    text-align: center;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h3 {
        font-weight: $font-weight-normal;
    }
}

// loading

.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;
}

.loading-shade-relative {
    position: relative;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: text;
    z-index: 10;
    background: transparent;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}


.line-full {
    width: 100%;
    height: 2px;
    background-color: $primary-color;
    margin-top: 5px;
    margin-bottom: 5px;
}

.cdk-global-scrollblock {
    overflow: unset;
}

.band {
    width: 100%;
    margin: 30px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;

    .card {
        background: $background-color-white;
        text-decoration: none;
        color: $light-black;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        transition: all .1s ease-in;

        .mat-card-header {
            text-align: center;
            justify-content: center;
        }

        .mat-card-actions {
            bottom: 20px;
            position: absolute;
            width: 96%;

            .action-btn {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        @media only screen and (max-width: 400px) {
            .mat-card-actions {
                position: relative;
                bottom: 0;
                width: unset;
            }
        }

        .mat-card-content {
            padding-bottom: 25px;

            .content {
                margin-top: 15px;
            }
        }

        .mat-card-w {
            height: 100%;

            .wrap-logo {
                display: flex;
                justify-content: center;
            }

            .mat-card-subtitle {
                font-size: $font-size-normal-2;
            }
        }

        // a {
        //     color: $text-color-black;
        // }

        .wrap-logo {
            cursor: pointer;
        }

        .img {
            cursor: pointer;
        }
    }

    .card:hover {
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    }

    .article {
        padding: 20px;
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.title-head {
    margin-bottom: 20px;
    font-weight: $font-weight-normal;
}

@media only screen and (max-width: 768px) {
    .title-head {
        margin-bottom: 10px;
    }
}

h1.title-head,
h2.title-head {
    word-break: break-word;
}

@media only screen and (max-width: 375px) {
    .h2.title-head,
    h2.title-head,
    .h2.w-title,
    h2.w-title {
        font-size: 1.7rem;
    }
    .h4, h4 {
        font-size: 1.3rem;
    }
}

h1.mat-dialog-title {
    font-size: 2.5rem;
}

h2.mat-dialog-title {
    font-size: 2rem;
}

h3.mat-dialog-title {
    font-size: 1.75rem;
}

.mat-paginator-page-size-label,
.mat-paginator-range-label,
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    color: $text-color-black;
    font-size: $font-size-small;
}

mat-form-field.search-string .mat-form-field-infix {
    width: 100% !important;
    max-width: 100%;
}

mat-form-field.search-string .mat-form-field-infix input {
    min-width: 250px;
}

mat-form-field.search-string .mat-form-field-flex {
    width: 100% !important;
    max-width: 100%;
}

.filter-wrapper,
.mat-card.filter-wrapper {
    padding: 0;
}

.table-container {
    position: relative;
    overflow: auto;
    width: 100%;
    max-width: 100%;
    user-select: text;

    table {
        width: 100%;
    }
}

.table-container table,
.banner-mat-row {
    td {
        font-weight: $font-weight-normal;
    }

    td.mat-cell,
    th.mat-cell {
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
    }

    th.mat-header-cell .mat-sort-header-container,
    th.mat-header-cell .header-container {
        width: fit-content;
        margin: auto;
    }

    @media only screen and (max-width: 500px) {
        td.mat-cell:first-of-type,
        td.mat-cell:last-of-type,
        td.mat-cell {
            padding: 7px;
        }
    }

    .mat-header-cell {
        font-size: $font-size-normal;
        font-weight: $font-weight-semi;
        color: $text-color-black;
    }
}

.mat-nav-list .mat-list-item.active {
    background: $background-color-black-2;
}

@media only screen and (max-width: 400px) {
    .withdrawForm {
        .form-group-amount {
            .mat-form-field-infix {
                width: 100px;
            }
        }
    }
}

@media (min-width: 440px) {
    .inviteForm {
        .form-inline {
            .form-group {
                display: flex;
                flex: 0 0 auto;
                flex-flow: row wrap;
                align-items: center;
                margin-bottom: 0;
            }

            label {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
            }

            .form-control {
                display: inline-block;
                width: auto;
                vertical-align: middle;
            }
        }
    }
}

.status-payment-com {
    .status {
        display: flex;

        .i-tooltip {
            margin-top: -2px;
            margin-left: 3px;
            margin-bottom: -11px;
        }

        .img {
            float: left;
            margin-left: 5px;
            padding: 2px 3px 15px 19px;
        }

        &.confirmed {
            color: $status-confirmed-color;

            .img {
                background: url("./../assets/images/status/status-confirmed.png") no-repeat center left;
            }
        }

        &.pending {
            color: $status-pending-color;

            .img {
                background: url("./../assets/images/status/status-pending.png") no-repeat center left;
            }
        }

        &.declined {
            color: $status-declined-color;

            .img {
                background: url("./../assets/images/status/status-declined.png") no-repeat center left;
            }
        }

        &.failed {
            color: $status-failed-color;

            .img {
                background: url("./../assets/images/status/status-failed.png") no-repeat center left;
            }
        }

        &.request {
            color: $status-request-color;

            .img {
                background: url("./../assets/images/status/status-request.png") no-repeat center left;
            }
        }

        &.paid {
            color: $status-paid-color;

            .img {
                background: url("./../assets/images/status/status-paid.png") no-repeat center left;
            }
        }

        &.payment_status {
            color: $status-payment-status-color;
        }
    }

    .extra-info {
        margin: 15px 0;
    }
}

.reviews-section {

    .mat-card-header-text {
        width: 100%;
    }

    @media (max-width: 500px) {
        .mat-card-header-text {
            margin: 0;
        }
    }

}

.review-list .band .card .mat-card-w .mat-card-header-text,
.offers-list .band .card .mat-card-w .mat-card-header-text,
.news-list .band .card .mat-card-w .mat-card-header-text {
    width: 100%;
    margin: 0;
}

.coupon-list .wrap-card .mat-card-header-text {
    width: 100%;

    .mat-card-title {
        margin: 0;
    }

    .mat-paginator {
        .mat-paginator-container {
            min-height: 48px;
        }
    }
}

@media (max-width: 575px) {
    .coupon-list .wrap-card .mat-card-header-text {
        margin: 0;

        .mat-paginator {
            margin: 10px 0;
        }
    }
}

.custom-snackbar-container {
    &.mat-snack-bar-container {
        max-width: 100%;
        padding: 5px 16px;
        line-height: unset;
    }
}

.nav-custom .mat-list-item .mat-list-item-content .mat-list-text {
    height: 100%;
    line-height: 60px;
}

.msg-badge {
    height: 22px;
    span.mat-badge-content {
        top: -6px !important;
    }
}

.cc-window {
    &.cc-banner {
        padding: 4px 12px;
        box-shadow: 0px -2px 2px 1px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

        .cc-btn {
            white-space: nowrap;
        }
    }
}

.banner-mat-row {
    width: 100%;

    .mat-cell {
        text-align: center;
        vertical-align: middle;

        a {
            display: block;
            position: relative;
        }

        .btn-t {
            display: flex;
            flex-direction: column;
        }

        .img {
            cursor: pointer;
            max-height: 200px;
            min-width: 350px;
        }

        .reorder {
            font-size: 40px;
            height: 40px;
            width: 40px;
        }

        .mat-column-id {
            width: 50px;
            padding: 0;
        }

        .mat-column-order {
            width: 40px;
        }
    }

    &.cdk-drag-preview {
        display: table;

        // td.mat-cell:first-of-type {
        //     padding-left: 7px;
        // }
    }
}

/* override ngb-boostrap for ngx-mat-datetime-picker*/

.ngx-mat-timepicker form .table {
    width: auto;
    margin-bottom: inherit;

    th, td {
        vertical-align: inherit;
        border-top: unset;
        padding: 0.5em;
    }
}

#fb-root {
    // iframe[data-testid=bubble_iframe] {
    //     display: none !important;
    //     z-index: 1 !important;
    // }

    #fb-customer-chat {
        display: none !important;
    }
}