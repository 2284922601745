@charset "UTF-8";

/*
Rating Component
========================================================================= */
.rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $rating-margin-bottom;

    /*Rating Value
  ====================================================*/
    @for $i from $minRatingValue through $maxNumOfStars {
        &.value-#{$i} {

            .star-container {
                .star:nth-child(-n+#{$i}) {
                    @extend .filled;
                }

                .star {
                    @if $i <=2 {
                        svg {
                            fill: $color-negative-rating;
                        }

                        i {
                            color: $color-negative-rating;
                        }
                    }

                    @if $i==3 {
                        svg {
                            fill: $color-ok-rating;
                        }

                        i {
                            color: $color-ok-rating;
                        }
                    }

                    @if $i>=4 {
                        svg {
                            fill: $color-positive-rating;
                        }

                        i {
                            color: $color-positive-rating;
                        }
                    }
                }
            }
        }
    }

    //Handling values > $maxNumOfStars up to $maxNumOfStars*2 (i know it's ugly. Maybe i will skip this completely)
    @for $i from $maxNumOfStars through $maxNumOfStars*2 {
        &.value-#{$i} {

            .star-container {
                .star:nth-child(-n+#{$i}) {

                    // @extend .star;
                    svg,
                    i {

                        &.star-empty,
                        &.star-filled {
                            opacity: 0;
                        }

                        &.star-filled {
                            opacity: 1;
                        }
                    }
                }

                .star {
                    @extend .positive;
                }
            }
        }
    }

    /*Hover states
  ====================================================*/

    //- all stars before and the hover star should be filled
    //- filled stars after the hover star should be empty

    &.hover:hover {

        @for $i from $minRatingValue through $maxNumOfStars {

            &.value-#{$i}.half,
            &.hover-#{$i} {
                .star-container {
                    .star {
                        @extend .empty;
                    }
                }
            }

            &.hover-#{$i} {
                .star-container {

                    .star:nth-child(-n+#{$i}) {
                        @extend .filled;
                    }

                    .star {
                        @if $i <=2 {
                            svg {
                                fill: $color-negative-rating;
                            }

                            i {
                                color: $color-negative-rating;
                            }
                        }

                        @if $i==3 {
                            @extend .ok;
                        }

                        @if $i>=4 {
                            @extend .positive;
                        }
                    }
                }
            }
        }

    }

    /*Half stars
  ====================================================*/

    //if we hals ratings > 0 && < 1 we color the first star
    &.value-0.half {
        .star {
            @extend .negative;
        }

        .star:nth-child(1) {
            @extend .half;
        }
    }

    @for $i from $minRatingValue through $maxNumOfStars {
        &.value-#{$i}.half {

            .star-container {
                @if ($i < $maxNumOfStars) {
                    .star:nth-child(#{$i+1}) {

                        // @extend .half;
                        svg,
                        i {

                            &.star-filled,
                            &.star-empty {
                                opacity: 0;
                            }

                            &.star-half {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

        }
    }

    /*Label Visible
  ====================================================*/
    &.label-hidden {
        .label-value {
            display: none;
        }
    }

    &.label-visible {
        display: flex;
    }

    /*Label Position
  ====================================================*/
    &.label-top {
        flex-direction: column;

        .label-value {
            +.star-container {
                margin-left: 0;
                margin-right: 0;
                margin-top: $default-stars-label-margin;
            }
        }
    }

    &.label-left .label-value {
        flex: 0 0 auto;

        +.star-container {
            margin-left: $default-stars-label-margin;
            margin-right: 0;
        }
    }

    &.label-right {
        flex-direction: row-reverse;

        .label-value {
            +.star-container {
                margin-left: 0;
                margin-right: $default-stars-label-margin;
            }
        }
    }

    &.label-bottom {
        flex-direction: column-reverse;

        .label-value {
            +.star-container {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: $default-stars-label-margin;
            }
        }
    }

    /*
  Direction
  ===================================================*/
    &.direction-rtl {
        direction: rtl;

        .star-container {
            direction: rtl;

            .star {

                svg,
                i {
                    &.star-half {
                        transform: scale(-1, 1);
                    }
                }
            }
        }
    }

    &.direction-ltr {
        direction: ltr;
    }

    /* Star Type
  ====================================================*/
    &.star-icon {
        @extend .star-icon1;
    }

    &.star-custom-icon {
        i {
            font-size: 18px;
            line-height: 18px;
        }
    }

    // &.star-svg {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;

    //     font-style: normal;

    //     &.star-half,
    //     &.star-filled {
    //         opacity: 0;
    //     }
    // }

    /* Sizes
  ==========================================================*/
    &.small {
        .star {
            width: $small-star-width;
            height: $small-star-height;

            i {
                font-size: $small-star-font-size;
                line-height: $small-star-line-height;
            }
        }

        .label-value {
            font-size: $small-label-font-size;
            line-height: $small-label-line-height;
        }
    }

    &.medium {
        .star {
            width: $default-star-width;
            height: $default-star-width;

            i {
                font-size: $default-star-font-size;
                line-height: $default-star-line-height;
            }
        }

        .label-value {
            font-size: $default-label-font-size;
            line-height: $default-star-line-height;
        }
    }

    &.large {
        .star {
            width: $large-star-width;
            height: $large-star-height;

            i {
                font-size: $large-star-font-size;
                line-height: $large-star-line-height;
            }
        }

        .label-value {
            font-size: $large-label-font-size;
            line-height: $large-star-line-height;
        }
    }

    /* Space
  ====================================================*/
    &.space-no {
        .star-container {
            flex: 1 1 auto;
            justify-content: center;
        }
    }

    &.space-between {
        .star-container {
            flex: 1 1 auto;
            justify-content: space-between;
        }
    }

    &.space-around {
        .star-container {
            flex: 1 1 auto;
            justify-content: space-around;
        }
    }

    /* Static Color
    ====================================================*/
    &.color-default {
        .star-container {
            .star {
                svg {
                    fill: $color-default-rating !important;
                }

                i {
                    color: $color-default-rating !important;
                }
            }
        }
    }

    &.color-ok {
        .star-container {
            .star {
                svg {
                    fill: $color-ok-rating !important;
                }

                i {
                    color: $color-ok-rating !important;
                }
            }
        }
    }

    &.color-positive {
        .star-container {
            .star {
                svg {
                    fill: $color-positive-rating !important;
                }

                i {
                    color: $color-positive-rating !important;
                }
            }
        }
    }

    &.color-negative {
        .star-container {
            .star {
                svg {
                    fill: $color-negative-rating !important;
                }

                i {
                    color: $color-negative-rating !important;
                }
            }
        }
    }

    /*Disabled
  ====================================================*/
    &.disabled {
        .label-value {
            opacity: $disabled-opacity;
        }

        .star-container {
            .star {
                opacity: $disabled-opacity;
            }
        }
    }

    /*AnimationSpeed
 ====================================================*/
    &.immediately .star-container {
        transition: all $immediately-animation-type;

        .star {
            transition: $immediately-animation-type;

            svg,
            i {
                transition: $immediately-animation-type;
            }
        }
    }

    &.noticeable {
        .star-container {
            transition: all $default-animation-speed $default-animation-type;

            .star {
                transition: all $default-animation-speed $default-animation-type;

                svg,
                i {
                    transition: all $default-animation-speed $default-animation-type;
                }
            }
        }
    }

    &.slow {
        .star-container {
            transition: all $slow-animation-speed $default-animation-type;

            .star {
                transition: all $slow-animation-speed $default-animation-type;

                svg,
                i {
                    transition: all $slow-animation-speed $default-animation-type;
                }
            }
        }
    }

}