.custom-dialog,
.custom-dialog-wide {
    max-width: 90vw !important;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
}

@media (min-width: 576px) {
    .custom-dialog {
        max-width: 540px !important;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        width: 66.666667%;
    }
}

@media (min-width: 768px) {
    .custom-dialog {
        max-width: 720px !important;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        width: 50%;
    }

    .custom-dialog-wide {
        max-width: 720px !important;
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
        width: 80%;
    }
}

@media (min-width: 992px) {
    .custom-dialog {
        max-width: 960px !important;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        width: 45%;
    }
}

@media (min-width: 1200px) {
    .custom-dialog {
        max-width: 1140px !important;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        width: 33.333333%;
    }

    .custom-dialog-wide {
        max-width: 1140px !important;
        min-width: 720px !important;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        width: 33.333333%;
    }
}

.custom-dialog-search-user {
    max-width: 90vw !important;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
}


.custom-dialog-subscribe,
.custom-dialog-wide,
.custom-dialog {

    .mat-dialog-actions {
        margin-bottom: 0;
    }

    .mat-dialog-container {
        padding-bottom: 10px;
        box-sizing: border-box;
    }

    .mat-dialog-content {
        textarea {
            overflow: hidden;
        }
    }

    @media (max-height: 650px) {
        .mat-dialog-content {
            max-height: 75vh;
        }
    }
}

@media only screen and (max-width: 450px) {

    .custom-dialog-wide,
    .custom-dialog {
        .mat-dialog-container {
            padding: 24px 15px 5px 15px;
        }

        .mat-dialog-content {
            margin: 0 -15px;
            padding: 0 15px;
        }
    }
}

// mailchimp subscribe dialog

.custom-dialog-subscribe {
    max-width: 99vw !important;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
}

@media (min-width: 576px) {
    .custom-dialog-subscribe {
        max-width: 540px !important;
        -ms-flex: 0 0 85%;
        flex: 0 0 85%;
        width: 85%;
    }
}

@media (min-width: 768px) {
    .custom-dialog-subscribe {
        max-width: 720px !important;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        width: 66.666667%;
    }
}

@media (min-width: 992px) {
    .custom-dialog-subscribe {
        max-width: 960px !important;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .custom-dialog-subscribe {
        max-width: 1140px !important;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        width: 41.666667%;
    }
}

.custom-dialog-subscribe {
    .mat-dialog-container {
        padding: 20px 0px 0px 0px;
    }

    .mat-dialog-content {
        margin: 0 0;
        padding: 0 0;
    }

    .mat-dialog-actions {
        margin: 0 20px;
    }
}