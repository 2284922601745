.star-filled1 {

    svg,
    i {

        &.star-empty,
        &.star-filled {
            opacity: 0;
        }

        &.star-filled {
            opacity: 1;
        }
    }
}

.star-icon1 {
    i {
        font-size: $default-star-font-size;
        line-height: $default-star-line-height;

        &.star-empty:before {
            content: $default-star-character-empty;
        }

        &.star-half:before {
            content: $default-star-character-half;
        }

        &.star-filled:before {
            content: $default-star-character-filled;
        }
    }
}